import axios from 'axios';
import { API_NAPBUS } from 'config/api.config';
import UserService from 'config/UserService';

export const getReportAnualData = async (year) => {
  const res = await axios.get(`${API_NAPBUS}/report/anual/${UserService.company()}/${year}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getVoucherReportData = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/report/vouchers/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getAccountingReportData = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/report/accounting/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getSellerReportData = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/report/seller/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getManualMovementsReport = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/report/salidas-manuales/${UserService.company()}`, data);
  return res.data;
};

export const getCanceledReport = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/report/canceled/${UserService.company()}`, data);
  return res.data;
};
