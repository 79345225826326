import ConfigRoutes from 'routes';
import ThemeConfig from 'theme';
import GlobalStyles from 'theme/globalStyles';
import { BrowserRouter } from 'react-router-dom';
import NapCobranzasProvider from 'context';
import moment from 'moment-timezone';
import 'moment/locale/es';
import SalesContextProvider from 'context/sales';

const App = () => {
  moment.tz.setDefault('America/Lima');

  return (
    <BrowserRouter>
      <SalesContextProvider>
        <ThemeConfig>
          <GlobalStyles />
          <NapCobranzasProvider>
            <ConfigRoutes />
          </NapCobranzasProvider>
        </ThemeConfig>
      </SalesContextProvider>
    </BrowserRouter>
  );
};

export default App;
