/* eslint-disable react/display-name */
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// material
import { Box } from '@mui/material';
import Scrollbar from './Scrollbar';

// ----------------------------------------------------------------------
const HEIGHT = 'calc((100vh) - 35px)';

const Page = forwardRef(({ children, title = '', ...other }, ref) => (
  <Box ref={ref} {...other}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Scrollbar sx={{ height: HEIGHT }}>
      <main style={{ height: HEIGHT, position: 'relative' }}>{children}</main>
    </Scrollbar>
  </Box>
));

export default Page;
