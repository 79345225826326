import axios from 'axios';
import { API_NAPBUS } from 'config/api.config';
import UserService from 'config/UserService';

export const getSerie = async () => {
  const res = await axios.get(`${API_NAPBUS}/serie/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postSerie = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/serie/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putSerie = async (idSerie, data) => {
  const res = await axios.put(`${API_NAPBUS}/serie/${idSerie}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
