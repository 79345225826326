import BackDrop from 'components/BackDrop';
import { createContext, useContext, useState, useEffect } from 'react';
import { getUserInfo } from 'requests';
import sleep from 'utils/sleep';
import { useNavigate, useLocation } from 'react-router-dom';

export const NapCobranzas = createContext();
export const useNapBus = () => useContext(NapCobranzas);

const exclude = ['/home', '/login', '/forgot-password'];

const NapCobranzasProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  // DATA CONTEXT
  const [userInfo, setUserInfo] = useState({});
  const [company, setCompany] = useState({});

  useEffect(() => {
    if (!exclude.includes(location.pathname)) getData();
    else setLoading(false);
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await getUserInfo();
      // const { data, message, token } = res;
      if (res.message) {
        // setCompany(res.data.company);
        setUserInfo(res.data);
      } else {
        await redirecTO();
      }
    } catch (error) {
      await redirecTO();
    } finally {
      setLoading(false);
    }
  };

  const redirecTO = async () => {
    await sleep(200);
    navigate('/login');
  };

  return (
    <NapCobranzas.Provider value={{ userInfo, company, setCompany }}>
      {children}
      <BackDrop loading={loading} />
    </NapCobranzas.Provider>
  );
};
export default NapCobranzasProvider;
