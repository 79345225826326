import axios from 'axios';
import { API_NAPBUS } from 'config/api.config';
import UserService from 'config/UserService';

export const getViajes = async (params) => {
  const res = await axios.get(`${API_NAPBUS}/trip/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
    params,
  });
  return res.data;
};

export const getTripDistribution = async (tripId) => {
  const res = await axios.get(`${API_NAPBUS}/trip/distribution/${tripId}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getViaje = async (idViaje) => {
  const res = await axios.get(`${API_NAPBUS}/trip/${idViaje}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postViaje = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/trip/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putViaje = async (idViaje, data) => {
  const res = await axios.put(`${API_NAPBUS}/trip/${idViaje}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const deleteViaje = async (idViaje) => {
  const res = await axios.delete(`${API_NAPBUS}/trip/${idViaje}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getViajeByDate = async (day, month, year) => {
  const res = await axios.get(`${API_NAPBUS}/trip/${day}/${month}/${year}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postHojaDeRuta = async (data, idViaje) => {
  const res = await axios.post(`${API_NAPBUS}/trip/roadMap/${idViaje}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
