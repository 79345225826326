import axios from 'axios';
import { API_NAPBUS } from 'config/api.config';
import UserService from 'config/UserService';

export const getCompanies = async () => {
  const res = await axios.get(`${API_NAPBUS}/company`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postCompanies = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/company`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putCompanies = async (data, id) => {
  const res = await axios.put(`${API_NAPBUS}/company/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putFormCompanies = async (data, id) => {
  const res = await axios.put(`${API_NAPBUS}/company/form/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
