import { lazy } from 'react';
import Loadable from './Loadable';

// ----------------------------------------------------------------------
const Page404 = Loadable(lazy(() => import('pages/Errors/Page404')));
// ----------------------------------------------------------------------
const DashboardLayout = Loadable(lazy(() => import('layouts/dashboard')));
const FreeLayout = Loadable(lazy(() => import('layouts/FreeLayout')));
// ----------------------------------------------------------------------
const Login = Loadable(lazy(() => import('pages/Login')));
const Home = Loadable(lazy(() => import('pages/Home')));
// ----------------------------------------------------------------------
const ForgotPassword = Loadable(lazy(() => import('pages/Errors/ForgotPwd')));
// ----------------------------------------------------------------------
const Dashboard = Loadable(lazy(() => import('pages/Dashboard')));
const Companies = Loadable(lazy(() => import('pages/Companies')));
const Pending = Loadable(lazy(() => import('pages/Sunat/Pending')));
const Comprobants = Loadable(lazy(() => import('pages/Sunat/Comprobants')));
const Manual = Loadable(lazy(() => import('pages/Sunat/Manual')));
const Generate = Loadable(lazy(() => import('pages/Sunat/Generate')));
const Historical = Loadable(lazy(() => import('pages/Sunat/Massives')));
const DetailsComprobant = Loadable(lazy(() => import('pages/Sunat/components/Details')));
const DetailsPDF = Loadable(lazy(() => import('pages/Sunat/components/PDF')));
const Reports = Loadable(lazy(() => import('pages/Reports')));
const ResumeReport = Loadable(lazy(() => import('pages/Reports/Resume')));
const VouchersReport = Loadable(lazy(() => import('pages/Reports/Vouchers')));
const AccountingReport = Loadable(lazy(() => import('pages/Reports/Accounting')));
const SellerReport = Loadable(lazy(() => import('pages/Reports/Seller')));
const ManualMovements = Loadable(lazy(() => import('pages/Reports/ManualMovements')));
const General = Loadable(lazy(() => import('pages/Reports/General')));
const Canceled = Loadable(lazy(() => import('pages/Reports/Canceled')));

// BUSES
const Buses = Loadable(lazy(() => import('pages/Buses')));
const Rutas = Loadable(lazy(() => import('pages/Rutas')));
const Viajes = Loadable(lazy(() => import('pages/Viajes')));
const Ventas = Loadable(lazy(() => import('pages/Ventas')));
const Encomiendas = Loadable(lazy(() => import('pages/Encomiendas')));

const CuadreCaja = Loadable(lazy(() => import('pages/CuadreCaja')));
const Tickets = Loadable(lazy(() => import('pages/Tickets')));
const HistorialPagos = Loadable(lazy(() => import('pages/HistorialPagos')));

const Users = Loadable(lazy(() => import('pages/Users')));
const Customers = Loadable(lazy(() => import('pages/Customers')));
const MethodPayments = Loadable(lazy(() => import('pages/Methods')));
const CajaRegistradora = Loadable(lazy(() => import('pages/CajaRegistradora')));
const Configuracion = Loadable(lazy(() => import('pages/Configuracion')));
const Printers = Loadable(lazy(() => import('pages/Printers')));

// ----------------------------------------------------------------------
// OTROS
const Profile = Loadable(lazy(() => import('pages/Profile')));

const routes = [
  {
    path: '/home',
    element: FreeLayout,
    roles: [],
    isPrivate: false,
    children: [
      {
        path: '',
        element: Home,
        roles: [],
      },
    ],
  },
  {
    path: '/',
    roles: ['superadmin', 'admin'],
    element: DashboardLayout,
    isPrivate: true,
    children: [
      {
        path: '',
        element: Dashboard,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'ventas',
        element: Ventas,
        roles: ['superadmin', 'admin', 'caja'],
      },
      {
        path: 'buses',
        element: Buses,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'rutas',
        element: Rutas,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'viajes',
        element: Viajes,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'encomiendas',
        element: Encomiendas,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'cuadre-de-caja',
        element: CuadreCaja,
        roles: ['superadmin', 'admin', 'caja'],
      },
      {
        path: 'caja-registradora',
        element: CajaRegistradora,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'tickets',
        element: Tickets,
        roles: ['superadmin', 'admin', 'caja'],
      },
      {
        path: 'historial-de-pagos',
        element: HistorialPagos,
        roles: ['superadmin', 'admin', 'caja'],
      },
      {
        path: 'configuracion',
        element: Configuracion,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'impresoras',
        element: Printers,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'perfil',
        element: Profile,
        roles: ['superadmin', 'admin', 'caja'],
      },
      // COMPONENTS
      {
        path: 'empresas',
        element: Companies,
        roles: ['superadmin'],
      },
      {
        path: 'usuarios',
        element: Users,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'sunat/pendientes',
        element: Pending,
        roles: ['superadmin', 'admin', 'caja'],
      },
      {
        path: 'sunat/emitidos',
        element: Comprobants,
        roles: ['superadmin', 'admin', 'caja'],
      },
      {
        path: 'sunat/generar',
        element: Manual,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'sunat/historico',
        element: Historical,
        roles: ['superadmin', 'admin', 'caja'],
      },
      {
        path: 'sunat/registrar',
        element: Generate,
        roles: ['superadmin'],
      },
      {
        path: 'sunat/detail-voucher/:idVoucher/:type/:from',
        element: DetailsComprobant,
        roles: ['superadmin', 'admin', 'caja'],
      },
      {
        path: 'sunat/detail-pdf/:idVoucher/:type',
        element: DetailsPDF,
        roles: ['superadmin', 'admin', 'caja'],
      },
      {
        path: 'reportes',
        element: Reports,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'reportes/historial',
        element: ResumeReport,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'reportes/:type',
        element: VouchersReport,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'reportes/contabilidad',
        element: AccountingReport,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'reportes/vendedores',
        element: SellerReport,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'reportes/movimientos',
        element: ManualMovements,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'reportes/general',
        element: General,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'reportes/anulados',
        element: Canceled,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'metodos/pago',
        element: MethodPayments,
        roles: ['superadmin', 'admin'],
      },
      {
        path: 'clientes',
        element: Customers,
        roles: ['superadmin', 'admin'],
      },

      // IF VIEW DOESN'T EXIST
      {
        path: '*',
        element: Page404,
        roles: ['superadmin', 'admin'],
      },
    ],
  },
  {
    path: '/login',
    element: Login,
    isPrivate: false,
    roles: [],
  },
  {
    path: '/forgot-password',
    element: ForgotPassword,
    isPrivate: false,
    roles: [],
  },
  {
    path: '*',
    element: Page404,
    isPrivate: false,
    roles: [],
  },
];

export default routes;
