import { notification } from 'antd';
import { createContext, useContext, useState } from 'react';
import { getTripDistribution } from 'requests/viajes';

export const SalesContext = createContext();

const SalesContextProvider = (props) => {
  const [distribution, setDistribution] = useState([]);

  const [viaje, setViaje] = useState(null); // Almacena la id del viaje seleccionado
  const [infoViaje, setInfoViaje] = useState({}); // Almacena la info del viaje seleccionado

  const [seats, setSeats] = useState([]); // Almacena los asientos seleccionados

  const [limitSeats, setLimitSeats] = useState(null); // Almacena el limite de asientos por viaje, si es null no hay limite

  const [actualLevel, setActualLevel] = useState(null); // Almacena el nivel actual de la distribución

  const resetContext = () => {
    setDistribution([]);
    setViaje(null);
    setInfoViaje({});
    setSeats([]);
    setLimitSeats(null);
    setActualLevel(null);
  };

  const resetSeats = () => {
    setInfoViaje({});
    setSeats([]);
  };

  const reloadDistribution = async (tripId) => {
    try {
      const distribution = await getTripDistribution(tripId);
      setDistribution(distribution);
    } catch (error) {
      notification.error({
        message: 'Error al obtener el estado de la caja',
      });
    }
  };

  const value = {
    distribution,
    setDistribution,
    viaje,
    setViaje,
    infoViaje,
    setInfoViaje,
    resetContext,
    seats,
    setSeats,
    resetSeats,
    limitSeats,
    setLimitSeats,
    actualLevel,
    setActualLevel,
    reloadDistribution,
  };

  return <SalesContext.Provider value={value}>{props.children}</SalesContext.Provider>;
};

/**
 *
 * @typedef {{
 *  distribution: any[],
 *  setDistribution: (distribution: any[]) => void,
 *  resetContext: () => void,
 *  viaje: any,
 *  setViaje: (viaje: any) => void,
 * infoViaje: any,
 * setInfoViaje: (infoViaje: any) => void,
 * seats: any[],
 * setSeats: (seats: any[]) => void,
 * limitSeats: number,
 * setLimitSeats: (limitSeats: number) => void,
 * actualLevel: number,
 * setActualLevel: (actualLevel: number) => void,
 * }} SalesContextType
 *
 *
 * @returns {SalesContextType}
 *
 *
 * @example
 * const { distribution, setDistribution, resetContext } = useSalesContext();
 */
export const useSalesContext = () => useContext(SalesContext);

export default SalesContextProvider;
