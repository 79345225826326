import axios from 'axios';
import { API_NAPBUS } from 'config/api.config';
import UserService from 'config/UserService';

export const getBillingCompany = async () => {
  const res = await axios.get(`${API_NAPBUS}/fact/information/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postBillingCompany = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/fact/register/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// type = 01 || 03
export const getVouchers = async (type, params = null) => {
  const res = await axios.get(
    `${API_NAPBUS}/fact/sended/${type}/${UserService.company()}${params ? `?lastDate=${params}` : ''}`,
    {
      headers: { Authorization: UserService.token() },
    },
  );
  return res.data;
};

export const getCreditNote = async () => {
  const res = await axios.get(`${API_NAPBUS}/creditnote/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const generateCreditNote = async (operationId, data) => {
  const res = await axios.post(`${API_NAPBUS}/creditnote/${operationId}/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const sendEmailVoucher = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/fact/send-email/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// typeVoucher => 01=factura; 03=boleta
export const getPendingVouchers = async (typeVoucher) => {
  const res = await axios.get(`${API_NAPBUS}/fact/pending/${typeVoucher}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const sendSunat = async (type, idOperation) => {
  const res = await axios.get(`${API_NAPBUS}/fact/sendSunat/${type}/${idOperation}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const sendMassiveBallots = async (ids) => {
  const res = await axios.post(`${API_NAPBUS}/fact/massiveSunat/${UserService.company()}`, ids, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// OBTENER ENVÍOS MASIVOS

export const getMassiveComprobants = async () => {
  const res = await axios.get(`${API_NAPBUS}/massive/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// REVISAR ESTADO DEL COMPROBANTE

export const checkStatusComprobants = async (id) => {
  const res = await axios.get(`${API_NAPBUS}/massive/status-ticket/${id}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// delete massive
export const deleteMassive = async (id) => {
  const res = await axios.delete(`${API_NAPBUS}/massive/${id}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// VOLVER A ENVIAR EL MASIVO

export const resendMassiveToSunat = async (id) => {
  const res = await axios.get(`${API_NAPBUS}/fact/reSendMassiveSunat/${id}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// ANULAR BEFORE SEND TO SUNAT

export const anularBoleta = async (id) => {
  const res = await axios.put(
    `${API_NAPBUS}/payment/anulated/${id}`,
    {},
    { headers: { Authorization: UserService.token() } },
  );
  return res.data;
};

export const anularManual = async (id) => {
  const res = await axios.put(
    `${API_NAPBUS}/payment/anulated-manual/${id}`,
    {},
    { headers: { Authorization: UserService.token() } },
  );
  return res.data;
};

export const getVouchersById = async (id) => {
  const res = await axios.get(`${API_NAPBUS}/payment/status/id/${id}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getCreditNoteById = async (id) => {
  const res = await axios.get(`${API_NAPBUS}/creditnote/id/${id}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getClientPayment = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/fact/info/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getPendingVouchersToSend = async () => {
  const res = await axios.get(`${API_NAPBUS}/fact/pendingQuantity/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
