import axios from 'axios';
import { API_NAPBUS } from 'config/api.config';
import UserService from 'config/UserService';

export const authUser = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/auth/signin`, data);
  return res.data;
};

export const getUserInfo = async () => {
  if (UserService.user() && UserService.company()) {
    const res = await axios.get(`${API_NAPBUS}/auth/validToken/${UserService.user()}/${UserService.company()}`, {
      headers: { Authorization: UserService.token() },
    });
    return res.data;
  } else {
    return null;
  }
};

export const postUsers = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/auth/signup/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getUsers = async () => {
  const res = await axios.get(`${API_NAPBUS}/user/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getUserByRole = async (role) => {
  const res = await axios.get(`${API_NAPBUS}/user/${UserService.company()}/${role}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putUsers = async (data, id) => {
  const res = await axios.put(`${API_NAPBUS}/user/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const changePassword = async (data) => {
  const res = await axios.post(`${API_NAPBUS}/auth/change_password`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const changeProfilePicture = async (data, id) => {
  const res = await axios.put(`${API_NAPBUS}/user/profile-picture/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
